/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@4.6.1/dist/css/bootstrap-grid.min.css
 * - /npm/slim-select@1.27.1/dist/slimselect.min.css
 * - /npm/codemirror@5.65.5/lib/codemirror.min.css
 * - /npm/codemirror@5.65.5/addon/scroll/simplescrollbars.css
 * - /npm/codemirror@5.65.5/theme/dracula.min.css
 * - /npm/microtip@0.2.2/microtip.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
